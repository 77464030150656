import CloseIcon from "@/assets/icons/svg/close-icon-white.svg";
import SubHeader from "@/components/SubHeader";
import Button from "@/components/button";
import axios from "@/axios";
import Loader from "@shared/loader";
import AddUserModal from "@shared/components/addUserModal";
import Dropdown from "@shared/components/dropdown-base";
import { checkColumnConfig, prePopulatedDropdownItems } from "@shared/utils/functions";

export default {
    name: "field-table",
    components: {
        SubHeader,
        CloseIcon,
        Button,
        Loader,
        AddUserModal,
        Dropdown,
    },
    created() {
        return this.$route.path;
    },

    data() {
        return {
            columns: [
                {
                    label: "Username",
                    field: "user_name",
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        multi: true,
                        isLoading: false,
                        query_key: "username",
                    },
                },
                {
                    label: "Email",
                    field: "email",
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        multi: true,
                        isLoading: false,
                    },
                },
                {
                    label: "Users position",
                    field: "position",
                    filterOptions: {
                        enabled: false, // disable filter for this column
                    },
                },
                {
                    label: "Telephone",
                    field: "mobile",
                    filterOptions: {
                        enabled: false, // disable filter for this column
                    },
                },
                {
                    label: "Role",
                    field: "role_ids",
                    sortable: false,
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        multi: true,
                        isLoading: false,
						query_key: 'role'
                    },
                },
                {
                    label: "Group",
                    field: "group_ids",
                    sortable: true,
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        multi: true,
                        isLoading: false,
						query_key: 'group'
                    },
                },
            ],
            roles: [],
            groups: [],
            client_id: "",
            loadingState: {
                userList: false,
                roles: false,
                groups: false,
            },
            usersList: [],
            searchTimeout: 500,
            selectedDropDownItems: null,
            searchParams: null,
            userData : {
                username: "",
                email: "",
                roles: [],
                groups: [],
                mobile: '',
                position: ''
            },
            tableKey: 1,
        };
    },
    computed: {
        // getColumns() {
        //     if (this.groups.length) {
        //         return prePopulatedDropdownItems(
        //             this.columns.map((el) => {
        //                 if (el.field === "group_ids" || el.field === "role_ids") {
        //                     return { ...el, filterOptions: { ...el.filterOptions, customDropdownItems: el.field === "group_ids" ? this.groups?.map(el => ({id:el.id, name:el.title})) : this.roles?.map(el => ({id:el.id, name:el.title})) } };
        //                 } else {
        //                     return el;
        //                 }
        //             }),
        //             this.usersList
        //         );
        //     } else {
        //         return prePopulatedDropdownItems(this.columns, this.usersList);
        //     }
        // },
        getRows(){
            return this.usersList
        }
		
    },
    async mounted() {
        if (!this.$store.getters.getClientId) {
            await this.$store.dispatch("fetchClientId");
        }
        this.client_id = this.$store.getters.getClientId;
        this.getClientData();
    },
    methods: {
        async addUser(userData) {
            const roleIds = userData?.roles.map((x) => ({
                role_id: userData?.roles.find((y) => y.id === x.id).id,
            }));
            const groupIds = userData?.groups.map((x) => ({
                group_id: userData?.groups.find((y) => y.id === x.id).id,
            }));
            let payload = {
                email: userData.email.trim(),
                user_name: userData.username,
                group_ids: groupIds,
                role_ids: roleIds,
                mobile: userData.mobile,
                position: userData.position,
            };
            try {
                let { data } = await axios.post("user/client", payload);
                this.$toast.success(data.message || "User created");
                this.usersList.push({
                    ...payload,
                    id: data.user_id,
                    group_ids: userData?.groups.map((group) => ({
                        group_id: group.id,
                        title: group.title,
                    })),
                    role_ids: userData?.roles.map((role) => ({
                        group_id: role.id,
                        title: role.title,
                    })),
                });
                this.$refs["adduserModal"].closeModal();
            } catch (error) {
                this.$toast.error(error.response.data.detail || "failed to add user");
            }
        },
        async handleShowModal() {
            this.selectedField = null;
            this.userData = {
                username: "",
                email: "",
                roles: [],
                groups: [],
                mobile: '',
                position: ''
            };
            this.$refs["adduserModal"].showUserModal();
            await this.fetchRoles();
            await this.fetchGroups();
        },
        async getUsersLists() {
            this.loadingState.userList = true;
            let url = `user/client/${this.client_id}`;
            let items = null;
            try {
                let { data } = await axios.get(url, { params: { ...this.searchParams } });
                items = data;
            } catch (error) {
                console.log(error, "error while fetching users");
            }
            this.loadingState.userList = false;
            return items;
        },
        async fetchRoles() {
            this.loadingState.roles = true;
            try {
                let { data } = await axios.get(`role/client/${this.client_id}`);
                this.roles = data;
            } catch (error) {
                console.log(error, "error");
            }
            this.loadingState.roles = false;
        },
        async fetchGroups() {
            this.loadingState.groups = true;
            try {
                let { data } = await axios.get(`group/client/${this.client_id}`);
                this.groups = data;
            } catch (error) {
                console.log(error, "error");
            }
            this.loadingState.groups = false;
        },
        checkColumnConfig,
        async updateColumnFilters(column, value /* , callback */) {
            let selected = {
                key: column.filterOptions.query_key || column.field,
                value: column.filterOptions.multi ? value.map((el) => el.name) : [value?.name],
            };
            if (!selected.value?.length) {
                delete this.selectedDropDownItems[selected.key];
                return (this.usersList = await this.getUsersLists());
            }

            this.selectedDropDownItems = { ...this.selectedDropDownItems, [selected.key]: selected.value };
            let newLists = await this.filterListDropdownItem();
            this.usersList = newLists;
        },
        async filterListDropdownItem() {
            this.loadingState.userList = true;
            let url = `user/client/users`;
            let items = null;
            try {
                let body = {
                    client_id: this.client_id,
                    ...this.selectedDropDownItems,
                };
                let { data } = await axios.post(url, body);
                items = data.data;
            } catch (error) {
                console.log(error, "error while fetching users");
            }
            this.loadingState.userList = false;
            return items;
        },
        searchFilter(column, event) {
            if (this.searchTimeout) clearTimeout(this.searchTimeout);
            const delay = 500;
            this.searchTimeout = setTimeout(() => {
                this.getSearchOptions(column, event);
            }, delay);
        },
        async getSearchOptions(column, event) {
            let search = {
                key: column.filterOptions.query_key || column.field,
                value: event,
            };
            this.searchParams = { ...this.searchParams, [search.key]: search.value };
            let filteredDrowDowns = await this.getUsersLists();
            this.columns = prePopulatedDropdownItems(this.columns, filteredDrowDowns);
        },
        clearFilters(){
            this.tableKey+=1;
            this.selectedDropDownItems = {};
            this.$refs.clientTable.reset();
            this.getClientData()
        },
        async getClientData(){
            this.usersList = await this.getUsersLists();
            await this.fetchRoles();
            await this.fetchGroups();
            this.columns = prePopulatedDropdownItems(
                this.columns.map((el) => {
                    if (el.field === "group_ids" || el.field === "role_ids") {
                        return { ...el, filterOptions: { ...el.filterOptions, customDropdownItems: el.field === "group_ids" ? this.groups?.map(el => ({id:el.id, name:el.title})) : this.roles?.map(el => ({id:el.id, name:el.title})) } };
                    } else {
                        return el;
                    }
                }),
                this.usersList
            );
        },
    },
};
